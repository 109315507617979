import React from "react";

export default function FeatureImgTwo() {
  return (
    <>
      <section id="support" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <h2 className="text-capitalize">
            Support spécialisé dans le <br />
            notariat
          </h2>
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6">
              <div className="about-content-left section-heading">

                <div className="single-feature mb-4 mt-5">
                  <div className="icon-box-wrap d-flex align-items-center mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/image-icon-2.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0 p-1">
                      <b>Assistance informatique. </b>
                      L'équipe technique est composée de techniciens qualifiés, à l'écoute et spécialisés dans le domaine du notariat.
                      Ils ont également la possibilité d'être sur site afin de vous assister en temps réel.
                    </p>
                  </div>
                </div>
                <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/image-icon-4.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0 p-1">
                      <b>Un service de proximité. </b>
                      Il est possible de créer des groupes de communication WhatsApp ou Teams entre collaborateurs et l'équipe technique pour les demandes urgentes.
                    </p>
                  </div>
                  <p></p>
                </div>
                {/* <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/image-icon-1.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0 p-2">
                      <b>Maintenance corrective. </b>
                      Elle permet de prévenir les dysfonctionnements, de vérifier de manière périodique le caractère opératoire du système informatique.
                    </p>
                  </div>
                  <p></p>
                </div> */}
                {/* <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/image-icon-3.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0 p-1">
                      <b>Maintenance préventive. </b>
                      Elle vise à empêcher tout incident technique au moyen de la prévention par un entretien régulier.
                      En ayant recours à la maintenance préventive, la sécurité est nécessairement améliorée.
                    </p>
                  </div>
                  <p></p>
                </div> */}
              </div>
            </div>
            <div className="col-md-6 margin-top">
              <div className="about-content-right">

                {/* <img
                  src="assets/img/image-14.png"
                  alt="about us"
                  className="img-fluid"
                /> */}
                {/* <img
                  src="assets/img/about-img.png"
                  alt="about us"
                  className="img-fluid"
                /> */}
                {/* <img
                  src="assets/img/notaire-informatique.png"
                  alt="support informatique notaire"
                  className="img-fluid"
                /> */}
                <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/image-icon-1.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0 p-2">
                      <b>Maintenance corrective. </b>
                      Elle permet de prévenir les dysfonctionnements, de vérifier de manière périodique le caractère opératoire du système informatique.
                    </p>
                  </div>
                  <p></p>
                </div>
                <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/image-icon-3.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0 p-1">
                      <b>Maintenance préventive. </b>
                      Elle vise à empêcher tout incident technique au moyen de la prévention par un entretien régulier.
                      En ayant recours à la maintenance préventive, la sécurité est nécessairement améliorée.
                    </p>
                  </div>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
