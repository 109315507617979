// import LatestNewsOne from "../../components/blogs/LatestNewsOne";
import Contact from "../../components/contact/Contact";
// import FaqStatic from "../../components/Faqs/FaqStatic";
import FeatureImgThree from "../../components/features/FeatureImgThree";
import FeatureImgTwo from "../../components/features/FeatureImgTwo";
import Footer from "../../layout/footer/Footer";
import Navbar from "../../layout/header/Navbar";
import Layout from "../../layout/Layout";
// import Subsribe from "../../components/newsletter/Subsribe";
// import Price from "../../components/prices/Price";
import Promo from "../../components/promo/Promo";
// import PromoTwo from "../../components/promo/PromoTwo";

// import VideoPromoTwo from "../../components/promo/VideoPromoTwo";
// import Screenshot from "../../components/screenshot/Screenshot";
// import Team from "../../components/team/Team";
// import BrandCarousel from "../../components/testimonial/BrandCarousel";
import HeroFour from "./HeroFour";
// import TestimonialTwo from "../../components/testimonial/TestimonialTwo";
import Whatsapp from "../../components/whatsapp";

// Components
import Seo from "../../components/seo/seo";
import Map from "../../components/Map";

export default function HomeFour() {
  return (
    <>
      {/* Dynamic Meta Tags */}
      < Seo
        title="Notatech - Société spécialisé dans le notariat et le support informatique"
        description="NotaTech est composé d’une équipe spécialisée dans la maintenance et la gestion de parc informatique d’études notariales sur Paris et ses alentours."
        canonical=""
      />

      <Layout>
        <Navbar darkBg onIndex />
        <HeroFour />
        <Whatsapp />
        <Promo />
        {/* <PromoTwo /> */}
        <FeatureImgTwo />
        <FeatureImgThree />
        {/* <VideoPromoTwo /> */}
        {/* <Price hasBg /> */}
        {/* <FaqStatic /> */}
        {/* <Screenshot /> */}
        {/* <Team bgColor="gray-light-bg" /> */}
        {/* <LatestNewsOne light /> */}
        <Contact bgColor />
        <Map />
        {/* <BrandCarousel /> */}
        {/* <TestimonialTwo /> */}
        {/* <Subsribe /> */}
        <Footer space />
      </Layout>
    </>
  );
}
