import React from "react";

export default function FeatureImgThree() {
  return (
    <>
      <section id="gestion" className="feature-section ptb-100">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-5">
              <div className="download-img">
                <img
                  src="assets/img/notaire-informatique-2.png"
                  alt="download"
                  width="800"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="feature-contents section-heading">
                <h2 className="text-capitalize">assistance personnalisée</h2>
                <p>
                  NotaTech vous accompagne dans toutes les dimensions essentielles de votre métier en vous offrant un
                  accompagnement personnalisé de la gestion de vos prestataires à l’optimisation de votre système d’information.
                </p>

                <div className="feature-content-wrap">
                  <ul className="nav nav-tabs feature-tab" data-tabs="tabs">
                    <li className="nav-item">
                      <a
                        className="nav-link active h6"
                        href="#tab6-1"
                        data-toggle="tab"
                      >
                        <span className="ti-server"></span>
                        NotaTech
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link h6"
                        href="#tab6-4"
                        data-toggle="tab"
                      >
                        <span className="ti-shield"></span>
                        NotaSecure
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link h6"
                        href="#tab6-2"
                        data-toggle="tab"
                      >
                        <span className="ti-vector"></span>
                        NotaDev
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link h6"
                        href="#tab6-3"
                        data-toggle="tab"
                      >
                        <span className="ti-reload"></span>
                        NotaRecycle
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content feature-tab-content">
                    <div className="tab-pane active" id="tab6-1">
                      <ul className="list-unstyled">
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="badge badge-circle badge-primary mr-3">
                                <span className="ti-check"></span>
                              </div>
                            </div>
                            <div>
                              <p className="mb-0">
                                Direction du système d'information (DSI)
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="badge badge-circle badge-primary mr-3">
                                <span className="ti-check"></span>
                              </div>
                            </div>
                            <div>
                              <p className="mb-0">
                                Cybersécurité, sécurisation des systèmes d'information (SI)
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="badge badge-circle badge-primary mr-3">
                                <span className="ti-check"></span>
                              </div>
                            </div>
                            <div>
                              <p className="mb-0">
                                Infogérance informatique
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="badge badge-circle badge-primary mr-3">
                                <span className="ti-check"></span>
                              </div>
                            </div>
                            <div>
                              <p className="mb-0">
                                Gestion de projet
                              </p>
                            </div>
                          </div>
                        </li>
                        {/* <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="badge badge-circle badge-primary mr-3">
                                <span className="ti-check"></span>
                              </div>
                            </div>
                            <div>
                              <p className="mb-0">
                                Collaboratively predominate vertical
                                manufactured
                              </p>
                            </div>
                          </div>
                        </li> */}
                      </ul>
                    </div>
                    <div className="tab-pane" id="tab6-2">
                      <div className="single-feature">
                        <div className="d-flex align-items-center mb-2">
                          <span className="ti-world rounded mr-3 icon icon-color-1"></span>
                          <h3 className="mb-0 h5" style={{
                            fontWeight: "600"
                          }}>Site internet</h3>
                        </div>
                        <p>
                          Création d'une nouvelle activité, reprise ou mis à jour d'un ancien site
                          web ou encore accompagnement de l'entreprise dans son entrée sur Internet,
                          nous pouvons vous apporter notre expertise, vous conseiller et vous proposer
                          des solutions adaptées à vos besoins.
                        </p>
                      </div>
                      <div className="single-feature mb-4">
                        <div className="d-flex align-items-center mb-2">
                          <span className="ti-user rounded mr-3 icon icon-color-2"></span>
                          <h3 className="mb-0 h5" style={{
                            fontWeight: "600"
                          }}>Outil métier</h3>
                        </div>
                        <p>
                          Développement spécialisé dans la réalisation d'outils métier sur mesure
                          pour répondre à des problématiques internes et spécifiques à votre cœur de métier.
                        </p>
                      </div>
                    </div>
                    <div className="tab-pane" id="tab6-3">
                      <div className="row">
                        <div className="col single-feature mb-4">
                          <div className="d-flex align-items-center mb-2">
                            <span className="ti-info rounded mr-3 icon icon-color-2"></span>
                            <h3 className="mb-0 h5" style={{
                              fontWeight: "600"
                            }}>Faites des économies !</h3>
                          </div>
                          <p>
                            Une deuxième vie pour vos appareils.
                          </p>
                        </div>
                        {/* <div className="col single-feature mb-4">
                          <div className="d-flex align-items-center mb-2">
                            <span className="ti-stats-up rounded mr-3 icon icon-color-3"></span>
                            <h5 className="mb-0">Product analytics</h5>
                          </div>
                          <p>
                            A top promo bar that counts down until a few
                            discounts.
                          </p>
                        </div> */}
                      </div>
                    </div>
                    {/* <div className="tab-pane" id="tab6-4">
                      <ul style={{ listStyleType: 'circle'}}>
                      <li className="mb-4">
                        Afin de vous accompagner dans la sécurisation de votre système d’information,
                        nous mettons à votre disposition une prestation permettant d’appréhender
                        l’ensemble de vos problématiques de cybersécurité.
                      </li>
                      <li>
                        NotaTech, propose des audits de vulnérabilité, des risques, afin de vous proposer
                        des solutions de sécurisation adaptées pour votre Étude.
                        De plus, nous pouvons faire une campagne de sensibilisation pour vos collaborateurs
                        sur l’importance de la sécurité informatique, dans le but d’éviter tout vol de donnée,
                        falsification d’identité ou d’intrusion informatique.
                      </li>
                    </ul>
                  </div> */}
                    <div className="tab-pane" id="tab6-4">
                      <div className="single-feature">
                        <div className="d-flex align-items-center mb-2">
                          <span className="ti-lock rounded mr-3 icon icon-color-1"></span>
                          <h3 className="mb-0 h5" style={{
                            fontWeight: "600"
                          }}>Sécurisation</h3>
                        </div>
                        <p>
                          Afin de vous accompagner dans la sécurisation de votre système d’information,
                          nous mettons à votre disposition une prestation permettant d’appréhender
                          l’ensemble de vos problématiques de cybersécurité.
                        </p>
                      </div>
                      <div className="single-feature mb-4">
                        <div className="d-flex align-items-center mb-2">
                          <span className="ti-search rounded mr-3 icon icon-color-2"></span>
                          <h3 className="mb-0 h5" style={{
                            fontWeight: "600"
                          }}>Audits</h3>
                        </div>
                        <p>
                          NotaTech, propose des audits de vulnérabilité, des risques, afin de vous proposer
                          des solutions de sécurisation adaptées pour votre Étude.
                          De plus, nous pouvons faire une campagne de sensibilisation pour vos collaborateurs
                          sur l’importance de la sécurité informatique, dans le but d’éviter tout vol de donnée,
                          falsification d’identité ou d’intrusion informatique.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
    </>
  );
}
