import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

// Components
import Layout from '../../layout/Layout';
import Navbar from '../../layout/header/Navbar';
import Footer from '../../layout/footer/Footer';
import PageHeader from '../../components/team/PageHeader';
import Seo from '../../components/seo/seo';

const PlanSite = () => {
    return (
        <>
            {/* Dynamic Meta Tags */}
            < Seo
                title="Plan du site internet | Notatech"
                description="Plan du site internet de la société Notatech."
                canonical="copyright"
            />
            <Layout>

                <Navbar darkBg />
                <PageHeader HeaderTitle="Plan du site" Parent="Pages" PageTitle="Plan du site" />
                <Container style={{
                    marginTop: "5%",
                    marginBottom: "8%"
                }}>
                    <h2 className='mb-4'>Navigation</h2>
                    <ul style={{
                        paddingLeft: "30px"
                    }}>
                        <li className='mb-3'>
                            <Link to="/" className='hover-primary' style={{ fontWeight: "600" }}>► Accueil</Link><br />
                            <Link to="/" className='hover-primary' style={{ paddingLeft: "20px" }}>https://notatech.fr/</Link>
                        </li>
                        <li className='mb-3'>
                            <Link to="/copyright" className='hover-primary' style={{ fontWeight: "600" }}>► Copyright</Link><br />
                            <Link to="/copyright" className='hover-primary' style={{ paddingLeft: "20px" }}>https://notatech.fr/copyright</Link>
                        </li>
                        <li className='mb-3'>
                            <Link to="/mentions-legales" className='hover-primary' style={{ fontWeight: "600" }}>► Mentions légales</Link><br />
                            <Link to="/mentions-legales" className='hover-primary' style={{ paddingLeft: "20px" }}>https://notatech.fr/mentions-legales</Link>
                        </li>
                        <li className='mb-3'>
                            <Link to="/politique-de-confidentialite" className='hover-primary' style={{ fontWeight: "600" }}>► Politque de confidentialité</Link><br />
                            <Link to="/politique-de-confidentialite" className='hover-primary' style={{ paddingLeft: "20px" }}>https://notatech.fr/politique-de-confidentialite</Link>
                        </li>
                    </ul>
                </Container>
                <Footer space />
            </Layout>
        </>
    );
};

export default PlanSite;