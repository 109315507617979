import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

// Components
import Layout from '../../layout/Layout';
import Navbar from '../../layout/header/Navbar';
import Footer from '../../layout/footer/Footer';
import PageHeader from '../../components/team/PageHeader';
import Seo from '../../components/seo/seo';

// Icons
import {
    RiArrowRightSFill
} from 'react-icons/ri';


const PolitiqueDeConfidentialite = () => {
    return (
        <>
            {/* Dynamic Meta Tags */}
            < Seo
                title="Cookies et politique de confidentialité Nextech"
                description="Cookies et politique de confidentialité sur le site www.notatech.fr."
                canonical="mentions-legales"
            />
            <Layout>
                <Navbar darkBg />
                <PageHeader HeaderTitle="Politique de confidentialité" PageTitle="Politique de confidentialité" />
                <Container style={{
                    marginTop: "5%",
                    marginBottom: "5%"
                }}>
                    <p>Lorsque vous accédez à notre site web <Link to="/">www.notatech.fr</Link>, il est possible que nous enregistrions divers cookies sur votre ordinateur, en fonction des paramètres configurés dans votre navigateur web.</p>

                    <h2 className='h3'>1. Cookies propriétaires générés par Notatech</h2>
                    <p>
                        Notre site web <Link to="/">www.notatech.fr</Link>, destiné aux visiteurs, prospects ou clients Notatech, utilise des "cookies propriétaires"
                        pour permettre un accès aux contenus associés à votre statut (client ou simple visiteur, averti ou professionnel).
                        Ces cookies techniques sont nécessaires pour gérer l'enchaînement des pages que vous demandez. Nous vous recommandons
                        de ne pas refuser ces cookies propriétaires, car ils sont utilisés exclusivement par Notatech. Certains cookies ont
                        une durée de vie limitée à votre session d'utilisation du site, tandis que d'autres peuvent être utilisés lors de vos
                        prochaines visites pour offrir une expérience de navigation adaptée à votre profil de visiteur.
                    </p>

                    <h2 className='h3 mt-4'>2. Cookies tiers</h2>
                    <p>
                        Ce site utilise un cookie tiers, <a href='https://analytics.google.com/analytics/web/provision/#/provision'>Google Analytics</a>, un service d'analyse de site internet fourni par Google Inc.
                        Ce service utilise des cookies pour recueillir des données sur l'utilisation du site, telles que votre adresse IP,
                        afin de générer des rapports d'activité du site à des fins d'analyse. Google peut communiquer ces données à des
                        tiers si la loi l'exige ou si ces tiers traitent les données pour le compte de Google. Vous pouvez désactiver
                        l'usage des cookies en sélectionnant les paramètres correspondants dans votre navigateur. Cependant, nous attirons
                        votre attention sur le fait que cette désactivation pourrait empêcher l'usage de certaines fonctionnalités de ce site.
                    </p>
                    <p>
                        En utilisant ce site Web, vous consentez expressément à ce que Google traite vos données personnelles conformément aux
                        conditions et objectifs décrits ci-dessus. Les transferts de données personnelles sont réalisés vers Google Inc.,
                        entreprise ayant adhéré au <a href="https://fr.wikipedia.org/wiki/Safe_Harbor">Safe Harbor</a> et offrant une protection
                        adéquate dans le traitement des données personnellesainsi transférées.
                    </p>
                    <p>
                        Le refus de cookies tiers n'affecte en rien votre expérience utilisateur. Elle nous prive simplement d'informations
                        utiles nous permettant d'analyser la provenance de nos visiteurs.
                    </p>

                    <h2 className='h3 mt-4'>3. L'accord sur les cookies</h2>
                    <p>
                        L'enregistrement d'un cookie dans votre terminal est soumis à votre consentement, que vous pouvez exprimer et modifier
                        à tout moment via les paramètres de votre navigateur. Si vous avez accepté l'enregistrement de cookies, ceux-ci seront
                        stockés temporairement dans votre terminal et seront lisibles uniquement par leur émetteur.
                    </p>

                    <h2 className='h3 mt-4'>4. Le refus sur les cookies</h2>
                    <p>
                        Si vous refusez l'enregistrement de cookies ou les supprimez, le site pourrait ne pas s'afficher correctement,
                        et certaines fonctionnalités nécessaires pour naviguer sur le site pourraient ne pas être disponibles.
                    </p>

                    <h2 className='h3 mt-4'>5. Comment exercer vos choix selon le navigateur internet que vous utilisez ?</h2>
                    <p>
                        Vous pouvez configurer votre navigateur pour accepter ou rejeter les cookies de manière systématique ou ponctuelle,
                        avant qu'un cookie soit enregistré. Les procédures varient selon les navigateurs, et vous pouvez trouver des instructions
                        dans le menu d'aide de votre navigateur. Voici des liens vers les procédures des principaux navigateurs :
                    </p>
                    <ul style={{
                        listStyle: "disc",
                        paddingLeft: "40px"
                    }}>
                        <li className='mb-2'>
                            <a href='https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en'>Chrome</a>
                        </li>
                        <li className='mb-2'>
                            <a href='https://support.mozilla.org/fr/kb/protection-renforcee-contre-pistage-firefox-ordinateur?redirectlocale=fr&redirectslug=activer-desactiver-cookies-preferences'>Firefox</a>
                        </li>
                        <li className='mb-2'>
                            <a href='https://support.apple.com/fr-fr/HT201265'>Safari</a>
                        </li>
                        <li className='mb-2'>
                            <a href='https://support.microsoft.com/fr-fr/microsoft-edge/supprimer-les-cookies-dans-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09#:~:text=Dans%20Microsoft%20Edge%2C%20s%C3%A9lectionnez%20Param%C3%A8tres,%20et%20autres%20donn%C3%A9es%20de%20site.'>Edge</a>
                        </li>
                        <li className='mb-2'>
                            <a href='https://support.microsoft.com/fr-fr/windows/supprimer-et-g%C3%A9rer-les-cookies-168dab11-0753-043d-7c16-ede5947fc64d'>Internet Explorer</a>
                        </li>
                        <li className='mb-2'>
                            <a href='https://help.opera.com/en/latest/web-preferences/#cookies'>Opera</a>
                        </li>
                    </ul>

                    <p className='mt-4'>N'hésitez pas à consulter le site de la CNIL pour en savoir plus sur la gestion des cookies en suivant ce lien : <a href="https://www.cnil.fr/fr/cookies-et-autres-traceurs/comment-se-proteger/maitriser-votre-navigateur">https://www.cnil.fr/fr/cookies-et-autres-traceurs/comment-se-proteger/maitriser-votre-navigateur</a></p>

                    <h2 className='h3 mt-4'>Mentions légales Notatch</h2>
                    <Link to="/mentions-legales"> <RiArrowRightSFill size={30}/>Suivez ce lien pour consulter les mentions légales de Notatech.</Link>


                    {/* <h2>Vos données, notre priorité</h2>
                    <p>Le but de cette politique de confidentialité est d'informer les utilisateurs de notre site
                        des données personelles que nous recueillerons ainsi que les informations
                        suivantes, le cas échéant :
                    </p>
                    <ul style={{
                        listStyle: "disc",
                        paddingLeft: "40px"
                    }}>
                        <li className='mb-2'>
                            Les données personnelles que nous recueillerons.
                        </li>
                        <li className='mb-2'>
                            L'utilisation des données recueillies.
                        </li>
                        <li className='mb-2'>
                            Qui a accès aux données recueillies.
                        </li>
                        <li className='mb-2'>
                            Les droits des utilisateurs du site.
                        </li>
                        <li className='mb-2'>
                            La politique de cookies du site.
                        </li>
                    </ul>
                    <p className='mt-4'>
                        Cette politique de confidentialité fonctionne parallèlement aux <Link to="/mentions-legales">mentions légales</Link> de notre site.
                    </p> */}


                    {/* <h2 className='mt-5'>Loi applicables</h2>
                    <p>
                        Conformément au <a href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees" target="_blank" rel="noreferrer">Règlement général sur la protection des données</a> (RGPD), cette politique de confidentialité est conforme aux règlements suivants.
                    </p>
                    <p>Les données à caractère personnel doivent être :</p>
                    <ul style={{
                        paddingLeft: "40px"
                    }}>
                        <li className='mb-2'>
                            <span style={{ fontWeight: "600" }}>a. </span>
                            traitées de manière licite, loyale et transparente au regard de la personne concernée (licéité, loyauté, transparence) ;
                        </li>
                        <li className='mt-3'>
                            <span style={{ fontWeight: "600" }}>b. </span>
                            collectées pour des finalités déterminées, explicites et légitimes, et ne pas être traitées ultérieurement d'une manière
                            incompatible avec ces finalités; le traitement ultérieur à des fins archivistiques dans l'intérêt public, à des fins de
                            recherche scientifique ou historique ou à des fins statistiques n'est pas considéré, conformément à <a href='https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre9' target="_blank" rel="noreferrer">l'article 89,
                                paragraphe 1</a>, comme incompatible avec les finalités initiales (limitation des finalités) ;
                        </li>
                        <li className='mt-3'>
                            <span style={{ fontWeight: "600" }}>c. </span>
                            adéquates, pertinentes et limitées à ce qui est nécessaire au regard des finalités pour lesquelles elles sont traitées (minimisation des données) ;
                        </li>
                        <li className='mt-3'>
                            <span style={{ fontWeight: "600" }}>d. </span>
                            exactes et, si nécessaire, tenues à jour; toutes les mesures raisonnables doivent être prises pour que les données à caractère personnel qui sont inexactes,
                            eu égard aux finalités pour lesquelles elles sont traitées, soient effacées ou rectifiées sans tarder (exactitude) ;
                        </li>
                        <li className='mt-3'>
                            <span style={{ fontWeight: "600" }}>e. </span>
                            conservées sous une forme permettant l'identification des personnes concernées pendant une durée n'excédant pas celle nécessaire au regard des finalités pour
                            lesquelles elles sont traitées; les données à caractère personnel peuvent être conservées pour des durées plus longues dans la mesure où elles seront traitées
                            exclusivement à des fins archivistiques dans l'intérêt public, à des fins de recherche scientifique ou historique ou à des fins statistiques conformément à
                            <a href='https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre9' target="_blank" rel="noreferrer"> l'article 89,
                                paragraphe 1</a>, pour autant que soient mises en œuvre les mesures techniques et organisationnelles appropriées requises par le règlement afin de
                            garantir les droits et libertés de la personne concernée (limitation de la conservation) ;
                        </li>
                        <li className='mt-3'>
                            <span style={{ fontWeight: "600" }}>f. </span>
                            traitées de façon à garantir une sécurité appropriée des données à caractère personnel, y compris la protection contre le traitement non autorisé ou illicite
                            et contre la perte, la destruction ou les dégâts d'origine accidentelle, à l'aide de mesures techniques ou organisationnelles appropriées (intégrité et confidentialité).
                        </li>
                    </ul>
                    <p className='mt-4'>Le traitement n'est licite que si, et dans la mesure où, au moins une des conditions suivantes est remplie :</p>
                    <ul style={{
                        paddingLeft: "40px"
                    }}>
                        <li className='mb-2'>
                            <span style={{ fontWeight: "600" }}>a. </span>
                            la personne concernée a consenti au traitement de ses données à caractère personnel pour une ou plusieurs finalités spécifiques ;
                        </li>
                        <li className='mt-3'>
                            <span style={{ fontWeight: "600" }}>b. </span>
                            le traitement est nécessaire à l'exécution d'un contrat auquel la personne concernée est partie ou à l'exécution de mesures
                            précontractuelles prises à la demande de celle-ci ;
                        </li>
                        <li className='mt-3'>
                            <span style={{ fontWeight: "600" }}>c. </span>
                            le traitement est nécessaire au respect d'une obligation légale à laquelle le responsable du traitement est soumis ;
                        </li>
                        <li className='mt-3'>
                            <span style={{ fontWeight: "600" }}>d. </span>
                            le traitement est nécessaire à la sauvegarde des intérêts vitaux de la personne concernée ou d'une autre personne physique ;
                        </li>
                        <li className='mt-3'>
                            <span style={{ fontWeight: "600" }}>e. </span>
                            le traitement est nécessaire à l'exécution d'une mission d'intérêt public ou relevant de l'exercice de l'autorité publique
                            dont est investi le responsable du traitement ;
                        </li>
                        <li className='mt-3'>
                            <span style={{ fontWeight: "600" }}>f. </span>
                            le traitement est nécessaire aux fins des intérêts légitimes poursuivis par le responsable du traitement ou par un tiers,
                            à moins que ne prévalent les intérêts ou les libertés et droits fondamentaux de la personne concernée qui exigent une protection des données à caractère personnel, notamment lorsque la personne concernée est un enfant.
                        </li>
                    </ul>


                    <h2 className='mt-5'>Consentement</h2>
                    <p>Les utilisateurs conviennent qu’en utilisant notre site, ils consentent à :</p>
                    <ul style={{
                        paddingLeft: "40px"
                    }}>
                        <li className='mb-2'>
                            <span style={{ fontWeight: "600" }}>a. </span>
                            les conditions énoncées dans la présente politique de confidentialité et
                        </li>
                        <li className='mt-3'>
                            <span style={{ fontWeight: "600" }}>b. </span>
                            la collecte, l’utilisation et la conservation des données énumérées dans la présente politique.
                        </li>
                    </ul>


                    <h2 className='mt-5'>Données personnelles que nous collectons</h2>
                    <p>
                        Nous ne collectons, ne stockons ni n’utilisons aucune donnée utilisateur sur notre site. Dans le cas
                        où nous avons besoin de recueillir vos données, nous veillerons à vous fournir un préavis de ce recueil de données.
                    </p>


                    <h2 className='mt-5'>Politique sur les cookies</h2>
                    <p>
                        Un cookie est un petit fichier, stocké sur le disque dur d’un utilisateur par le site Web. Son but est de recueillir
                        des données relatives aux habitudes de navigation de l’utilisateur.
                    </p>
                    <p>Nous utilisons les types de cookies suivants sur notre site :</p>
                    <ul style={{
                        paddingLeft: "40px"
                    }}>
                        <li className='mb-2'>
                            <span style={{ fontWeight: "600" }}>► Cookies fonctionnels </span><br />
                            Nous les utilisons pour mémoriser toutes les sélections que vous faites sur notre site afin qu’elles
                            soient sauvegardées pour vos prochaines visites.
                        </li>
                    </ul>
                    <p className='mt-4'>
                        Vous pouvez choisir d’être averti chaque fois qu’un cookie est transmis. Vous pouvez également choisir
                        de désactiver les cookies entièrement dans votre navigateur Internet, mais cela peut diminuer la
                        qualité de votre expérience d’utilisation.
                    </p>


                    <h2 className='mt-5'>Modifications</h2>
                    <p>
                        Cette politique de confidentialité peut être modifiée à l’occasion afin de maintenir la conformité avec
                        la loi et de tenir compte de tout changement à notre processus de collecte de données. Nous recommandons
                        à nos utilisateurs de vérifier notre politique de temps à autre pour s’assurer qu’ils soient informés de
                        toute mise à jour. Au besoin, nous pouvons informer les utilisateurs par courriel des changements
                        apportés à cette politique.
                    </p> 

                    <h2 className='mt-5'>Contact</h2>
                    <p>Si vous avez des questions à nous poser, n’hésitez pas à communiquer avec nous en utilisant ce qui suit :</p>
                    <p>Téléphone : <a href="tel:0188819999">01 88 81 99 99</a></p>
                    <p>Email : <a href="mailto:contact@notatech.fr">contact@notatech.fr</a></p>
                    <p>Adresse : 5 rue de Rome Rosny-sous-Bois 93110</p>
*/}

                </Container>
                <Footer space />

            </Layout>
        </>
    );
};

export default PolitiqueDeConfidentialite;