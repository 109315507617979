import React from "react";
import { Link } from "react-router-dom";

// Icons
import { AiOutlineTwitter } from 'react-icons/ai';
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa';


export default function Footer({ space }) {
  return (
    <>
      <footer className="footer-section">
        <div
          className={`footer-top background-img-2 ${space ? "pt-60" : "pt-60"}`}
          style={{
            background:
              "url('assets/img/footer-bg.png') no-repeat center top / cover",
          }}
        >
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
                <div className="footer-nav-wrap text-white">
                  <Link to="/">
                    <img
                      src="assets/img/logo-white-1x.png"
                      alt="footer logo"
                      width="120"
                      className="img-fluid mb-3"
                    />
                  </Link>
                  <p>
                    Une équipe spécialisée depuis plus de 10 ans dans la maintenance
                    et la gestion de parc informatique auprès d’études notariales.
                  </p>

                  <div className="social-list-wrap">
                    <ul className="social-list list-inline list-unstyled">
                      <li className="list-inline-item">
                        <a href="https://www.facebook.com/notatechfr/" target="_blank" rel="noreferrer" title="Facebook" className="social-btn">
                          <span><FaFacebookF size="1.3rem" /></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="https://twitter.com/Notatechfr" target="_blank" rel="noreferrer" title="Twitter" className="social-btn">
                          {/* <span className="ti-twitter"></span> */}
                          <span><AiOutlineTwitter size="1.4rem" /></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="https://www.linkedin.com/company/notatech/" target="_blank" rel="noreferrer" title="Linkedin" className="social-btn" style={{
                          paddingLeft: "1.5px"
                        }}>
                          <span><FaLinkedinIn size="1.3rem" /></span>
                        </a>
                      </li>
                      {/* <li className="list-inline-item">
                        <a href="#/" target="_blank" title="Instagram">
                          <span className="ti-instagram"></span>
                        </a>
                      </li> */}
                      {/* <li className="list-inline-item">
                        <a href="#/" target="_blank" title="printerst">
                          <span className="ti-pinterest"></span>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>


              <div className="col-md-12 col-lg-8">
                <div className="row">

                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white text-capitalize">Contact</h5>
                      <ul className="list-unstyled support-list">
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-location-pin mr-2"></span>
                          <a href="https://www.google.com/maps/place/5+Rue+de+Rome,+93110+Rosny-sous-Bois/@48.8755883,2.4725302,17z/data=!3m1!4b1!4m6!3m5!1s0x47e612c72e2f988f:0xa939a06b4727373c!8m2!3d48.8755848!4d2.4751051!16s%2Fg%2F11b8v42j32?hl=fr" target="_blank" rel="noreferrer">
                            5 rue de Rome                          <br />
                            Rosny-sous-Bois 93110
                          </a>
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-mobile mr-2"></span>
                          <a href="tel:0188819999"> 01 88 81 99 99</a>
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-email mr-2"></span>
                          <a href="mailto:contact@notatech.fr">
                            {" "}
                            contact@notatech.fr
                          </a>
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-world mr-2"></span>
                          <a href="/"> www.notatech.fr</a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Services</h5>
                      <ul className="list-unstyled">
                        <li className="mb-2 text-capitalize">
                          <a href="#/">Assistance informatique</a>
                        </li>
                        <li className="mb-2 text-capitalize">
                          <a href="#/">Support informatique</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">DSI & Infogérances</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Supervisions</a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Contrats</h5>
                      <ul className="list-unstyled support-list">
                        {/* <li className="mb-2 text-capitalize">
                          <a href="#about">nous découvrir</a>
                        </li> */}
                        {/* <li className="mb-2">
                          <a href="#/">Careers</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Customers</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Community</a>
                        </li> */}
                        <li className="mb-2 text-capitalize">
                          <a href="#team">Accompagnement</a>
                        </li>
                        <li className="mb-2 text-capitalize">
                          <a href="#team">maintenance</a>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom border-gray-light mt-5 py-3">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-7">
                  <div className="copyright-wrap small-text">
                    <p className="mb-0 text-white">
                      <Link to="/copyright" className="copyright text-white">©</Link> {new Date().getFullYear()} <Link to="/" className="text-white">Notatech</Link>, Tous droits réservés
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5">
                  <div className="terms-policy-wrap text-lg-right text-md-right text-left">
                    <ul className="list-inline">
                      {/* <li className="list-inline-item">
                        <a className="small-text" href="#/">
                          Plan du site
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="small-text" href="#/">
                          Sécurité
                        </a>
                      </li> */}
                      <li className="list-inline-item">
                        <a className="small-text" href="/plan-du-site" style={{
                          borderRight: "1px solid hsla(0,0%,100%,.3)",
                          paddingRight: "15px",
                          marginRight: "10px"
                        }}>
                          Plan du site
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="small-text" href="/mentions-legales" style={{
                          borderRight: "1px solid hsla(0,0%,100%,.3)",
                          paddingRight: "15px",
                          marginRight: "10px"
                        }}>
                          Mentions légales
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="small-text" href="/politique-de-confidentialite">
                          Confidentialité
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
