import React from "react";

const Promo = () => {
  return (
    <>
      <section className="promo-section ptb-100" id="services">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-8">
              <div className="section-heading text-center mb-5">
                <h2>Que pouvons-nous faire pour vous ?</h2>
                <p className="lead">
                  Différents services sont disponibles, le contrat d'accompagnement ou de maintenance, le support technique, un DSI et chef de projet externalisé.
                </p>
              </div>
            </div>
          </div>
          <div className="row equal">
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-vector text-white"></span>
                </div>
                <h3 className="h5">DSI & Infogérances</h3>
                <p>Un centre de service composé de techniciens qualifiés à l’écoute pour répondre à toutes vos demandes avec le soutien d'une équipe DSI.</p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-lock text-white"></span>
                </div>
                <h3 className="h5">Assistance</h3>
                <p>
                  Une panne ? Une défaillance ? Contactez un expert qualifié à n'importe quel moment de la journée.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-eye text-white"></span>
                </div>
                <h3 className="h5">Contrats</h3>
                <p>
                  Des solutions intelligentes sur mesure adaptées à votre cœur de métier pour une meilleure gestion de votre système d'information.
                </p>
              </div>
            </div>
            {/* <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-eye text-white"></span>
                </div>
                <h5>Supervisions</h5>
                <p>
                  Realize importance of social proof in customer’s purchase
                  decision.
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Promo;
