import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

// Components
import Layout from '../../layout/Layout';
import Navbar from '../../layout/header/Navbar';
import Footer from '../../layout/footer/Footer';
import PageHeader from '../../components/team/PageHeader';
import Seo from '../../components/seo/seo';


const MentionsLegales = () => {
    return (
        <>
            {/* Dynamic Meta Tags */}
            < Seo
                title="Mentions légales de Notatech"
                description="Informations légales du site et mentions légales de Notatech."
                canonical="mentions-legales"
            />

            <Layout>
                <Navbar darkBg />
                <PageHeader HeaderTitle="Mentions Légales" Parent="Pages" PageTitle="Mentions légales" />
                <Container style={{
                    marginTop: "5%",
                    marginBottom: "5%"
                }}>
                    <h2>Éditeur</h2>
                    <p>
                        Le site <Link>www.notatech.fr</Link> est édité par Notatech, domiciliée au 5 rue de Rome 93110, Rosny-sous-Bois (France),
                        SAS au capital social de 10 000 € - SIREN : 905248787. Notatech est totalement indépendante et reste totalement
                        maître du contenu éditoriale du site.
                    </p>

                    <h2 className='mt-5'>Hébergement</h2>
                    <p>Le site www.notatech.fr est hébergé par la société Notatech et peut-être joint aux contacts suivants :</p>
                    <p>Téléphone : <a href="tel:0188819999">01 88 81 99 99</a></p>
                    <p>Email de l'éditeur : <a href="mailto:contact@notatech.fr">contact@notatech.fr</a></p>

                    <h2 className='mt-5'>Réutilisation des contenus</h2>
                    <p>
                        L’ensemble du matériel du site www.notatech.fr, incluant les textes, les éléments graphiques, les éléments sonores
                        et audiovisuels, les outils de suivi, est la propriété de Notatech, éditeur du site, et est protégé par les lois
                        internationales sur les droits d’auteur. Aucun matériel de ce site ne peut être reproduit, modifié, distribué,
                        transmis, republié, affiché ou diffusé sans l’autorisation écrite préalable de Notatech. Pour toute demande relative
                        à l’utilisation du matériel, veuillez écrire à : <a href="mailto:contact@notatech.fr">contact@notatech.fr</a>.
                    </p>

                    <h2 className='mt-5'>Données à caractère personnel</h2>
                    <p>
                        Vos données à caractère personnel sont exclusivement destinées au responsable de traitement et aux autres Utilisateurs
                        du Site et ne seront en aucun cas communiquées à des tiers non autorisés. Vous bénéficiez d’un droit d’accès, de rectification
                        et d’opposition des données à caractère personnel vous concernant que vous pouvez exercer en vous adressant par voie postale à
                        Notatech, 5 rue de Rome 93110, Rosny-sous-Bois (France) ou par mail à contact@notatech.fr.
                    </p>

                    <h2 className='mt-5'>Non-responsabilité</h2>
                    <p>
                        Le site www.notatech.fr contient des liens permettant un accès direct à d’autres sites internet externes. Notatech ne reconnaît aucune
                        responsabilité sur l’information diffusée par ces sites externes, et n’exerce sur celle-ci aucun contrôle éditorial.
                    </p>


                </Container>
                <Footer space />
            </Layout>
        </>
    );
};

export default MentionsLegales;