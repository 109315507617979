import React, { useState } from 'react'
import axios from 'axios'
import ReCAPTCHA from 'react-google-recaptcha';

// Components
import Modal from '../modal/modal';


export default function Contact({ bgColor }) {

  const recaptchaRef = React.createRef();

  const [openModal, setOpenModal] = useState(false);

  // Setter
  const [postData, setPostData] = useState({
    Nom: "",
    Prenom: "",
    Telephone: "",
    Email: "",
    Entreprise: "",
    Message: ""
  });

  // Nettoyer les champs 
  const clearState = () => {
    setPostData({
      Nom: "",
      Prenom: "",
      Telephone: "",
      Email: "",
      Entreprise: "",
      Message: ""
    });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setPostData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault(); // éviter rafraichissement page 

    // Vérifier la validation du reCAPTCHA
    const token = await recaptchaRef.current.executeAsync();

    // Soumettre le formulaire uniquement si le reCAPTCHA est validé
    if (token) {
      axios.post('https://formspree.io/f/meqwznvj', {
        ...postData,
        // recaptchaToken: token, // Ajouter le token reCAPTCHA aux données
      })
        .then(function (res) {
          setOpenModal(true);
          clearState();
        })
        .catch(function (err) {
          // console.error(error);
        });
    }
  };
  

  return (
    <>
      {/* if openModal == true then we runing Modal component  */}
      {openModal && <Modal setOpenModal={setOpenModal} />}

      <section
        id="contact"
        className={`contact-us ptb-100 ${bgColor ? "gray-light-bg" : ""}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 pb-3 message-box d-none">
              <div className="alert alert-danger"></div>
            </div>
            <div className="col-md-5">
              <div className="section-heading">
                <h2>Contact</h2>
                <p>
                  Nos conseillers vous accompagnent toute la semaine pour répondre à votre demande.
                  N’hésitez pas à nous laisser un message si vous souhaitez être recontactés.
                </p>
              </div>
              <div className="footer-address">
                <h3 className='h6 fw-bold' style={{
                  color: "#707070"
                }}>
                  Notatech Paris
                </h3>
                {/* <p>5 rue de Rome, Rosny-sous-Bois 93110.</p> */}
                <ul>
                  <li className='mb-1'>
                    <span>5 rue de Rome, Rosny-sous-Bois (93110).</span>
                  </li>
                  <li>
                    <span>Téléphone : <a href="tel:0188819999">01 88 81 99 99</a></span>
                  </li>
                  {/* <li>
                    <span>
                      Email :
                      <a href="mailto:contact@notatech.fr" className="p-1">
                        contact@notatech.fr
                      </a>
                    </span>
                  </li> */}
                </ul>
                <h3 className='h6 fw-bold mt-4' style={{
                  color: "#707070"
                }}>
                  Notatech Sud
                </h3>
                <ul>
                  <li className='mb-1'>
                    <span>Marsillargues (34590).</span>
                  </li>
                  <li>
                    <span>Téléphone : <a href="tel:0486802989">04 86 80 29 89</a></span>
                  </li>
                  <li className='mt-4'>
                    <span>
                      Email :
                      <a href="mailto:contact@notatech.fr" className="p-1">
                        contact@notatech.fr
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-7">
              <form
                id="contactForm"
                method="POST"
                className="contact-us-form"
                onSubmit={handleSubmit}
              >
                {/* <h5>Prise de contact rapide</h5> */}
                <div className="row mt-3">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="Nom"
                        placeholder="Entrer votre Nom"
                        required="required"
                        value={postData.Nom}
                        onChange={e => handleChange(e)}
                        />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="Telephone"
                        defaultValue=""
                        className="form-control"
                        placeholder="Votre Téléphone"
                        value={postData.Telephone}
                        onChange={e => handleChange(e)}
                        />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="Prenom"
                        placeholder="Entrer votre Prénom"
                        required="required"
                        value={postData.Prenom}
                        onChange={e => handleChange(e)}
                        />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="Email"
                        placeholder="Votre Email"
                        required="required"
                        value={postData.Email}
                        onChange={e => handleChange(e)}
                        />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="Entreprise"
                        defaultValue=""
                        size="40"
                        className="form-control"
                        placeholder="Étude/CRPCEN"
                        required="required"
                        value={postData.Entreprise}
                        onChange={e => handleChange(e)}
                        />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        name="Message"
                        className="form-control"
                        rows="7"
                        cols="25"
                        placeholder="Message"
                        required="required"
                        value={postData.Message}
                        onChange={e => handleChange(e)}
                        ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 mt-3">

                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="invisible"
                      sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                      style={{ display: "none" }}
                    />

                    <button
                      type="submit"
                      className="btn solid-btn"
                      id="btnContactUs"
                    >
                      Envoyer
                    </button>
                  </div>
                </div>
              </form>
              <p className="form-message"></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
