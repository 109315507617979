import React from "react";


// Images
import logo from '../../assets/logo/logo.png'

export default function Modal({ setOpenModal }) {

    const modalBackground = {
        backgroundColor: "hsla(0,0%,100%,.8)",
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        cursor: "pointer",
        zIndex: '999999'
    };

    const modalBody = {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 auto"
    }

    const modalContainer = {
        width: '380px',
        height: '278px',
        borderRadius: '12px',
        backgroundColor: 'white',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        display: 'flex',
        flexDirection: 'column',
        padding: '14px 25px 25px'
    };

    const titleCloseBtn = {
        display: 'flex',
        justifyContent: 'flex-end'
    };

    const titleCloseBtnButton = {
        backgroundColor: 'transparent',
        border: 'none',
        fontSize: '19px',
        cursor: 'pointer',
        color: '#000'

    };

    const sizeButton = {
        width: '37%'
    };

    const modalLogo = {
        width: '30%'
    }

    return (
        <>
            <div style={modalBackground}>
                <div style={modalBody}>
                    <div className="text-center" style={modalContainer}>
                        <div style={titleCloseBtn}>
                            <button style={titleCloseBtnButton} onClick={() => { setOpenModal(false); }}>X</button>
                        </div>
                        <div className="text-center">
                            <img style={modalLogo} src={logo} alt="Notatech, équipe spécialisée dans la maintenance et la gestion de parc informatique d’études notariales sur Paris" />
                        </div>
                        <div className="mt-2">
                            <p style={{
                                fontWeight: "500"
                            }}>Votre message a bien été envoyé !</p>
                            <button className="btn solid-btn mt-2" onClick={() => { setOpenModal(false); }} style={sizeButton}>Ok</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}