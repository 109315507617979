import React from 'react';

// Maps Elements 
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    Circle
} from 'react-leaflet';
import { Icon } from 'leaflet';

// Icon
import markerIcon from '../../assets/markers/location.png';

// CSS
import 'leaflet/dist/leaflet.css';

// Images 
import imgLogo from '../../assets/logo/logo.png'


const Map = () => {

    // Vérifier si nous sommes côté client avant de rendre la carte
    const isClient = typeof window !== 'undefined';

    const defaultCenter = [44.8404, 1.8785];

    const markerData = [
        { position: [48.87630776451518, 2.475855068441035], text1: '01 88 81 99 99', text2: 'Rosny-sous-Bois (93)', tel: '0188819999' },
        { position: [43.665397445439176, 4.177466035768026], text1: '04 86 80 29 89', text2: 'Marsillargues (34)', tel: '0486802989' },
    ];

    const parisCenter = [48.87630776451518, 2.475855068441035];
    const marsillarguesCenter = [43.665397445439176, 4.177466035768026];
    const circleRadius = 200000; // Définir le rayon souhaité en mètres



    // Condition pour afficher la carte uniquement côté client
    if (!isClient) {
        // Rendu côté serveur ou autre traitement côté serveur
        return null;

    } else {
        return (

            <div className='map-component'>
                <MapContainer center={defaultCenter} zoom={5.4} scrollWheelZoom={true} attributionControl={false} style={{
                    width: '100%',
                    height: '50vh',
                    cursor: 'url(https://maps.gstatic.com/mapfiles/openhand_8_8.cur), default',
                }}>
                    <TileLayer
                        // url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {/* <Marker position={position} icon={new Icon({ iconUrl: markerIcon, iconSize: [50, 45], iconAnchor: [25, 10] })} >
                        <Popup>
                            <p className='text-warning fw-bold'><span className='text-dark'>Montpellier (34000)</span></p>
                        </Popup>
                    </Marker> */}
                    {markerData?.map((item, index) => (
                        <Marker key={index} position={item.position} icon={new Icon({
                            iconUrl: markerIcon,
                            iconSize: [50, 45],
                            iconAnchor: [25, 10],
                        })}>
                            <Popup>
                                <div className='wrapper-marker'>
                                    <img src={imgLogo} alt='image logo notatech' className='marker-logo' /><br />
                                    <div className='mt-1'>
                                        <a href={`tel:${item.tel}`} className='marker-link'><span className='marker-span h6'>{item.text1}</span></a>
                                    </div>
                                    <div className='mt-2'>
                                        <span className='marker-span-ville'>{item.text2}</span>
                                    </div>
                                </div>
                            </Popup>
                        </Marker>
                    ))}

                    {/* Cercle pour Paris */}
                    <Circle
                        center={parisCenter}
                        radius={circleRadius}
                        pathOptions={{ fillColor: 'blue', color: 'blue' }}
                    />

                    {/* Cercle pour Marsillargues */}
                    <Circle
                        center={marsillarguesCenter}
                        radius={circleRadius}
                        pathOptions={{ fillColor: 'purple', color: 'purple' }}
                    />

                </MapContainer>
            </div>

        );
    }
};

export default Map;
