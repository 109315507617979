import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';


// Components
import Layout from '../../layout/Layout';
import Navbar from '../../layout/header/Navbar';
import Footer from '../../layout/footer/Footer';
import PageHeader from '../../components/team/PageHeader';
import Seo from '../../components/seo/seo';

const Copyright = () => {
    return (
        <>
            {/* Dynamic Meta Tags */}
            < Seo
                title="Régles relatives au droit d'auteur"
                description="Les textes et éléments constitutifs du site sont protégés contre la reproduction et la contrefaçon par les lois en vigueur relatives à la protection intellectuelle."
                canonical="copyright"
            />

            <Layout>

                <Navbar darkBg />
                <PageHeader HeaderTitle="Copyright" Parent="Pages" PageTitle="Copyright" />
                <Container style={{
                    marginTop: "5%",
                    marginBottom: "5%"
                }}>
                    <h2>Règles relatives au droit d'auteur ©</h2>
                    <p>
                        Nous tenons à rappeler que les textes et créations présentes sur le présent site
                        sont l’unique propriété de <Link to="/">Notatech</Link> et que toute reproduction, même
                        partielle, est strictement interdite sauf accord préalable et écrit.
                    </p>
                    <p>
                        En plus d'enfreindre les droits de propriété intellectuelle, les moteurs de recherche
                        comme Google pénalisent fortement le contenu dupliqué qui consiste en une copie du même
                        texte plusieurs fois sur différents sites.
                    </p>
                    <p>
                        Cette mauvaise pratique est néfaste pour le référencement des sites impliqués est peut-être
                        lourde de conséquences pour les deux parties concernées.
                    </p>
                    <p>
                        Pour en savoir plus sur les règles relatives au copyright nous vous conseillons de vous informer
                        et de prendre connaissance de vos responsabilités sur les <a href="https://www.inpi.fr/comprendre-la-propriete-intellectuelle/les-autres-modes-de-protection/le-droit-dauteur" target="_blank" rel="noreferrer">sites officiels en ligne</a>.
                    </p>
                    <p>
                        Toute l'équipe de Notatech vous remercie par avance pour votre considération.
                    </p>
                </Container>
                <Footer space />
            </Layout>
        </>
    );
};

export default Copyright;