import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

// Owl Carousel
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

//React Modal
import "react-modal-video/scss/modal-video.scss";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);