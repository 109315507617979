import React from "react";
import AppRoutes from "./routes/Routes";

const App = () => {
  return (
    <AppRoutes />
  );
};

export default App;
