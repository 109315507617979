import React from "react";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";

export default function Cookie() {

    const cookieConsent = getCookieConsentValue();

    return (
        <>
            {!cookieConsent &&
                <CookieConsent
                    location="bottom"
                    buttonText="Accepter"
                    cookieName="CookieNextech"
                    style={{ background: "#fff", color: "black", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
                    buttonStyle={{ background: "#6730e3", color: 'white', fontSize: "13px" }}
                    enableDeclineButton flipButtons
                    declineButtonText="Refuser"
                    declineButtonStyle={{ background: "grey", color: 'white', fontSize: "13px" }}

                    expires={180}
                >
                    Nous utilisons des cookies pour optimiser votre expérience et notre service.
                    <a href='/' style={{ paddingLeft: 'unset !important', color: '#6730e3' }}> En savoir plus</a>.
                </CookieConsent >
            }
        </>
    )
}