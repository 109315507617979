import React from "react";
import { Link } from "react-router-dom";


// Images
import pic from "../../assets/bg/bg.jpg"

export default function NotFound() {
  return (
    <>
      <section
        className="hero-section ptb-100 background-img full-screen"
        style={{
          background:
            `url(${pic})no-repeat center center / cover`,
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-9 col-lg-7">
              <div className="error-content text-center text-white">
                <div className="notfound-404">
                  <h1 className="text-white">404</h1>
                </div>
                <h3 className="text-white">Erreur, Page Introuvable</h3>
                <p>
                  La page que vous recherchez semble introuvable.
                </p>
                <Link className="btn app-store-btn" to="/">
                  Retour à l'accueil
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
