import React from "react";
import ReactWhatsapp from 'react-whatsapp';

import {
    BsWhatsapp
} from 'react-icons/bs';

export default function Whatsapp() {
    return (
        <>
            <div className="whatsapp-button">
                <ReactWhatsapp number="+33602574545" message="Bonjour, je suis intéressé par l'un de vos services. Serait-il possible que vous me rappeliez à ce numéro ? Merci." >
                    <BsWhatsapp color='white' size={30} />
                </ReactWhatsapp>
            </div>
        </>
    )
}